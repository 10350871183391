ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
}

ion-menu.md ion-list {
    padding: 20px 0;
}

ion-menu.md ion-note {
    margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
    padding-left: 10px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
    font-size: 16px;
    margin-bottom: 18px;
    color: #757575;
    min-height: 26px;
}

ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
}

ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
    color: #616e7e;
}

ion-menu.md ion-item ion-label {
    font-weight: 500;
}

ion-menu.ios ion-content {
    --padding-bottom: 20px;
}

ion-menu.ios ion-list {
    padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
}

ion-menu.ios ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
    font-size: 24px;
    color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
    color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
    margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
}

ion-menu.ios ion-note {
    margin-bottom: 8px;
}

ion-note {
    display: inline-block;
    font-size: 16px;
    color: var(--ion-color-medium-shade);
}

ion-item.selected {
    --color: var(--ion-color-primary);
}

.onboardingTitles {
    font-weight: 800 !important;
    font-size: 24px !important;
}
.onboardingLabels {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 18px !important;
}
.profileInputs {
    font-weight: 800;
    color: #808080;
    font-size: 18px;
}
.profileLabels {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 18px !important;
}
.onboardingInputs {
    font-weight: 800;
    color: #585858;
    font-size: 20px;
}
.placesSearch {
    padding: 5px;
}
.suggestionContainer ul {
    list-style-type: none;
    padding-inline-start: 0;
}
.suggestionContainer ul li {
    list-style-type: none;
    padding: 5px 0;
}
.deets {
    font-weight: 700;
    font-size: 1.25rem;
}

.profileDeets {
    font-weight: 700;
    font-size: 1.2rem !important;
    padding: 5px 0;
}
.react-tel-input {
    margin-top: 15px;
}
.StripeElement,
.StripeElementIdeal,
.StripeElementFpx {
    margin: 20px auto !important;
}
.onboardModal .modal-wrapper {
    border-radius: 10px;
}
.foot {
    text-align: center;
}

ol.terms li {
    margin-bottom: 15px;
}
